import styled from 'styled-components'
import { media } from '../../themes/index'

export const Title = styled.h1`
  font-weight: 300;
  text-transform: uppercase;
  font-size: 5rem;
  letter-spacing: 1.4rem;
  text-align: center;

  ${media.tablet`
    font-size: 2.8rem;
    letter-spacing: 0.3rem;
  `}

  ${media.phone`
    font-size: 2.3rem;
    letter-spacing: initial;
  `}
`

Title.displayName = 'Title'

export const SubTitle = styled.h2`
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.5rem;
  text-align: center;
  margin-bottom: 0.5rem;

  ${media.tablet`
    font-size: 2rem;
    letter-spacing: 0.2rem;
  `}
`
SubTitle.displayName = 'SubTitle'

export const Main = styled.main`
  padding: 2rem;
  padding-bottom: 50px;

  ${media.phone`
    padding-right: 1rem;
    padding-left: 1rem;
  `}
`

const contentSizes = {
  normal: { width: '70vw', maxWidth: '920px' },
  small: { width: '50vw', maxWidth: '620px' },
}

export const Content = styled.div.attrs(props => (
{
  contentSize: props.size ? props.size : 'normal',
}))`
  width: ${props => contentSizes[props.contentSize].width};
  max-width: ${props => contentSizes[props.contentSize].maxWidth};
  margin: 0 auto;

  p a {
    font-weight: 600;
    color: black;
    text-decoration: none;
    border-bottom: 2px dotted #999;
  }

  ${media.tablet`
    width: 100%;
  `}
`
