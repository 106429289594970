import React from 'react'
import Layout from '../components/layout'

import { Title, Main, Content } from '../components/styles/pages'

const PostPageTemplate = ({ data, pageContext, windowWidth, windowHeight }) => {
  const { title, body, slug } = pageContext
  // const { sizes, composition, no_frame_shadow } = post
  // const { next, previous } = pageContext

  // const gutter = 150

  // const dst = {
  //   width: windowWidth - gutter,
  //   height: windowHeight - gutter,
  // }

  // const src = {
  //   width: sizes.width,
  //   height: sizes.height,
  // }

  // const scale = Math.min(dst.width / src.width, dst.height / src.height)
  return (
    <Layout>
      <Main>
        <h1>{title}</h1>
        {/* <Content as="article" size="small">
          <ArticleHeader>
            <ArticleTitle>{title}</ArticleTitle>
            {author && <Author>{author}</Author>}
          </ArticleHeader>
          <CmsBody body={body} />
        </Content> */}
      </Main>
    </Layout>
  )
}

export default PostPageTemplate
